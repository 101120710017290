import React, {useState} from 'react';
import parse from "html-react-parser";
import {Link} from 'gatsby'

const mediapost = ({post, link}) => {
    return (
                            <div className="d-flex mb-4 media-object">
                                <div className="flex-shrink-0">
                                    <Link to={link}><img src={post.thumbnail_url}  alt={post.title_en}/></Link>
                                </div>
                                <div className="flex-grow-1 ms-3 text-muted">
                                    <h2 className="mt-0"><Link to={link}>{post.title_en} <small className="text-muted"><i>Posted on                         { (new Date(post.creation_date_timestamp * 1000)).toLocaleDateString() }
</i></small></Link></h2>
                                    {parse(post.summary_en)}
                                </div>
                            </div>



    )
}

export default mediapost;