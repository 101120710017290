import * as React from "react";


// Horizontal Responsive Display Ad 2022-08-08
// <Adsense client="ca-pub-6505108883414662" slot="5487091126" format="auto" />
// Display Square  Responsive
// <Adsense client="ca-pub-6505108883414662" slot="1689564034" format="auto" />
const Adsense = ({client, slot, format = 'auto'}) => (
  <div>
  <ins className="adsbygoogle"
  data-ad-client={client}
  data-ad-slot={slot}
  data-ad-format={format} data-full-width-responsive="true"></ins>
  </div>
)

export default Adsense;